/* Section page video carousel */
.ui-video-carousel {
    position: relative;
    overflow: hidden;
    margin-bottom: 50px;
}

.ui-video-carousel .section-title {
    font-size: var(--font-size-h4);
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 25px;
    text-align: center;
}

/* ui-video-carousel items */
.ui-video-carousel-item {
    margin: 0 auto;
    max-width: 1366px;
}

/* custom ui-box-video */
.ui-video-carousel .ui-box-video {
    margin-bottom: 0;
    opacity: 0;
    transition: .3s opacity;
}

    .ui-video-carousel .ui-box-video .content {
        margin: 16px 0 0;
        text-align: center;
    }

    .ui-video-carousel .ui-box-video .headline-serif {
        font-size: var(--font-size-heading1);
        margin-bottom: 12px;
        text-transform: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ui-video-carousel .ui-box-video .meta {
        color: var(--grey-a700);
        font-size: var(--font-size-body1);
        line-height: 1.43;
        margin: 5px 0 8px;
        text-transform: uppercase;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .ui-video-carousel .ui-box-video .description {
        color: var(--grey-a700);
        font-size: var(--font-size-caption);
        line-height: 1.33;
        margin: 5px 0 0;
    }

        .ui-video-carousel .ui-box-video .description span {
            margin: 0 5px;
            display: block;
        }

    .ui-video-carousel .ui-box-video .image-ratio::before {
        background: var(--grey-100);
    }

    .ui-video-carousel .ui-box-video .video a {
        position: static;
        height: 0;
    }

.ui-video-carousel .slide .ui-box-video {
    opacity: 1;
}

/* custom styles for RS near by  */
.ui-video-carousel .rsVisibleNearbyWrap {
    height: auto !important;
}

    .ui-video-carousel .rsVisibleNearbyWrap .rsOverflow {
        position: relative;
    }

#youtube-player-container {
    position: relative;
}

.ui-video-carousel .ytp-controls-bar {
    bottom: 0;
    z-index: 4;
}

/* custom styles for page editor */
.page-editor .ui-video-carousel .ui-box-video {
    max-width: 750px;
    opacity: 1;
    margin: 0 auto 40px;
}

/* BP 3+ */
@media screen and (min-width: 768px) {
    .ui-video-carousel .section-title {
        font-size: var(--font-size-heading1);
        margin-bottom: 30px;
        line-height: 1;
    }
    .ui-video-carousel .ui-box-video .headline-serif {
        font-size: var(--font-size-heading2);
    }

    .ui-video-carousel .ui-box-video .meta {
        margin: 5px 0 10px;
    }

    .ui-video-carousel .ui-box-video .description {
        margin: 5px 0 0;
    }

        .ui-video-carousel .ui-box-video .description span {
            display: inline-block;
        }
}

/* BP 1-4 */
@media screen and (max-width: 1024px) {
    .ui-video-carousel .rsVisibleNearbyWrap {
        overflow: hidden;
    }
}

/* BP 5 */
@media screen and (min-width: 1025px) {
    .ui-video-carousel {
        max-width: 2375px;
    }
}